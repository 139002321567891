import axios from 'axios';


const api = axios.create({
    baseURL: 'https://metisias.com/api/user',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
});

export const getPosttype = async () => api.get('/post-type/all');
export const getCategories = async (post_type) => api.get(`/categories/get/${post_type}`);
export const getCategoriesByPostType = async (post_type) => api.get(`/categories/getbyposttype/${post_type}`);
export const getSlider = async () => api.get('/slider');

export const getPosts = async (post_type, category, sub_category, sub_child_category, page) => api.get(`/posts/get/${page}/${post_type}/${category}/${sub_category}/${sub_child_category}`);
export const getPost = async (post_type, slug) => api.get(`/post/${post_type}/${slug}`);
export const getLatestPost = async () => api.get('/posts/latest');
export const getSubCategories = async (post_type, category) => api.get(`/subcategory/${post_type}/${category}`);
export const createContact = async (data) => api.post('/contact/create', data);
export const createSubscriber = async (data) => api.post('/subcribe', data);

export const getSubChildCategories = async (post_type, category, sub_category) => api.get(`/subcild-categories/get/${post_type}/${category}/${sub_category}`);
export const getDocuments = async (page) => api.get(`/documents/get/${page}`)