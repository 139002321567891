import { NavLink } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import {useSelector} from 'react-redux';
import MenuItem from "./MenuItem";

export default function Header ({headerRef}) {
    const [stick, setStick] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    //const headerRef = useRef();

    const {postTypes} = useSelector(state => state.postTypes)

    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 30) {
            setStick(true);
          } else {
            setStick(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


    return (
        <section id="header" style={{position:'fixed', top: '0'}} ref={headerRef}>
      <div id="header-top" style={{display: stick ? 'none' : 'block'}}>
        <div className="my-container" style={{display: 'flex', justifyContent: 'space-between'}}>
        <a href='mailto:info@metisias.com' target="_blank" className="footer-link footer-contact" style={{color: '#fff'}}>
                                        <i class="fa-solid fa-location-dot"></i> {/* Adjust padding here */}
                                        <span>info@metisias.com</span>
                                    </a>
                                    <div style={{display: 'flex'}}>
                                    <a href='https://metisiastests.com/' className="footer-link footer-contact" style={{color: '#fff', marginRight: '15px'}}>
                                        <span>Test</span>
                                    </a>
                                    <NavLink to='/about-us' className="footer-link footer-contact" style={{color: '#fff', marginRight: '15px'}}>
                                        <span>About Us</span>
                                    </NavLink>
                                    <NavLink to='/contact-us' className="footer-link footer-contact" style={{color: '#fff'}}>
                                        <span>Contact Us</span>
                                    </NavLink>
                                    </div>
        </div>
      </div>
      <div className="my-container nav-box" style={{position: 'sticky', top: 0, padding: stick ? '3px 0' : '10px 0'}}>
        <nav className="row nav-b-x-inner">
          <NavLink to={'/'}><img alt="logo" src="/assets/img/metis-logo.png" style={{width: '120px'}} /></NavLink>
          <div className={`nav-mobie-overlay ${collapsed ? 'collapsed' : ''}`}>
            <div className="nav-mobile-sidebar">
              <div className="mobile-nav-header">
              <NavLink to={'/'}><img alt="logo" src="/assets/img/metis-logo.png" style={{width: '120px'}} /></NavLink>
              <div className="btn-gr-border">
              <button className="btn primary-button" style={{width: '40px', height: '40px', padding: '0', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => setCollapsed(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
</svg>
              </button>
              </div>
              </div>
              <div className="mobile-nav-body">
              <div className="navbar-nav mx-auto nav-mobile">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink to={'/'} onClick={() => setCollapsed(true)}><a className={`nav-link`} >Home</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/downloads'} onClick={() => setCollapsed(true)}><a className={`nav-link`} >Downloads</a></NavLink>
              </li>
              {
                postTypes.map((pt) => pt.slug != 'blogs' && <MenuItem setCollapsed={setCollapsed} label={pt.label} slug={pt.slug} id={pt._id} />)
              }
              <li className="nav-item">
                <NavLink onClick={() => setCollapsed(true)} to={'/blogs'}><p className={`nav-link`} >Blogs</p></NavLink>
              </li>
             
              
            </ul>
           
          </div>
              </div>
            </div>
          </div>
          <div className="navbar-nav mx-auto nav-desktop">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink to={'/'}><p className={`nav-link`} >Home</p></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/downloads'}><p className={`nav-link`} >Downloads</p></NavLink>
              </li>
              {
                postTypes.map((pt) => pt.slug != 'blogs' && <MenuItem setCollapsed={setCollapsed} label={pt.label} slug={pt.slug} id={pt._id} />)
              }
              <li className="nav-item">
                <NavLink to={'/blogs'}><p className={`nav-link`} >Blogs</p></NavLink>
              </li>
              
            </ul>
          </div>
 
          <div className="toggle">
          <div className="toggle">
          <div className="btn-gr-border" style={{borderRadius: '7px'}}>
            <button className="btn primary-button" style={{width: '40px', height: '40px', padding: '0', display: 'flex', justifyContent:'center', alignItems:'center'}} onClick={() => setCollapsed(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
</svg>
            </button>
            </div>
          </div>
          </div>

        </nav>
      </div>
    </section>
    )
}