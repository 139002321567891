
import './App.css';
import Layout from './components/Layout';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import store from './store';
import {Provider} from 'react-redux'

import Home from './pages/Home';
import PostType from './pages/PostType';
import Category from './pages/Category';
import Post from './pages/Post';
import Blogs from './pages/Blogs';

import { getPosttype } from './http';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addPostType } from './store/slices/postTypes';
import About from './pages/About';
import Contact from './pages/Contact';
import Mains from './pages/Mains'
import Downloads from './pages/Downloads'


const router = createBrowserRouter([{
  path: '/',
  element: <Layout />,
  children: [
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/downloads',
      element: <Downloads />
    },

    {
      path: '/blogs',
      element: <Blogs />
    },
    {
      path: '/mains',
      element: <Mains />
    },
    {
      path: '/about-us',
      element: <About />
    },
    {
      path: '/contact-us',
      element: <Contact />
    },
    {
      path: '/posts/:post_type',
      element: <PostType />
    },
    {
      path: '/posts/:post_type/:category',
      element: <Category />
    },
    {
      path: '/post/:post_type/:slug',
      element: <Post />
    }
  ]
}])

function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPostType = async () => {
      try {
        const {data} = await getPosttype();
        dispatch(addPostType(data.postTypes))
        console.log('post type', data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchPostType()
  }, [])
  return (
   
      <RouterProvider router={router} />
    
  );
}

export default App;
