import { useState, useEffect } from "react"
import PageHeader from "../components/PageHeader";
import { Pagination } from "@mui/material";
import { getDocuments } from "../http";

export default function Downloads () {

    const [documents, setDocuments] = useState([{title:'test', url:'#'}]);
    const [total, setTotal] = useState(1)
    const [page, setPage] = useState(1)

    useEffect(() => {
        const fechDoc = async () => {
            try {
                const {data} = await getDocuments(page);
                setDocuments(data.downloads);
                setTotal(data.total)
            } catch (error) {
                console.log(error)
            }
        }
        fechDoc()
    }, [page])

    return (
        <div>
            <PageHeader label="Downloads" slug='' />

            <section id="post-type" style={{padding: '60px 0'}}>
                        
                     <div className="my-container">
                    
                        <div className="grid-container">
                          
                          {
                            documents.map((post) => (
                                <div className="craousel-card" style={{cursor: 'pointer'}}>
                                <a href={post.url} target="_blank"><div className="craouser-card-box">
                                 <div className="craouser-card-heading">
                                     <h2 className="secondary-heading">{post.title.length > 25 ? `${post.title.slice(0, 25)}...` : post.title}</h2>
                                 </div>
                                </div></a>
                             </div>
                            ))
                          }
                            
                           </div>
                     </div>
                     {total > 9 && (<div style={{display: 'flex', justifyContent: 'center', paddingTop: '30px'}}>
                        <Pagination count={Math.ceil(total/9)} page={page} onChange={(e, v) => setPage(v)} variant="outlined" color="secondary" />
                     </div>)}
                    </section>
        </div>
    )
}